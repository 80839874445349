<template>
    <div class="flex">
        <template v-for="(item, index) in items" class="flex items-center">
            <template v-if="index < Object.keys(items).length - 1">
                <router-link :to="{ name: item.routeName, params: (item.params ?? {}) }" class="font-bold text-primary text-xs">
                    {{ item.text }}
                </router-link>
                <div class="text-xs font-medium cursor-default mx-2">|</div>
            </template>
            <div v-else class="font-bold text-xs cursor-default">
                {{ item.text }}
            </div>
        </template>
    </div>
</template>

<script>
    export default {
        name: 'Breadcrumb',

        computed: {
            items() {
                return this.$store.state.breadcrumbItems;
            }
        }
    };
</script>
