<template>
    <div>
        <Header></Header>
        <div class="container mx-auto py-4">
            <breadcrumb></breadcrumb>
            <div class="md:grid md:grid-cols-5 md:gap-4 mt-2">
                <account-menu></account-menu>
                <router-view class="col-span-4"></router-view>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
    import Header from '@/components/Header';
    import Breadcrumb from '@/components/layout/Breadcrumb.vue';
    import AccountMenu from '@/components/account/Menu.vue';
    import Footer from '@/components/Footer';

    export default {
        name: 'Account',

        components: {
            Header,
            Breadcrumb,
            AccountMenu,
            Footer
        }
    };
</script>
