<template>
    <div class="border border-gray-400 rounded-3xl p-6 font-bold mb-4 md:mb-0">
        <div class="flex items-center" :class="{ 'text-primary': isActive('AccountSetting') }">
            <font-awesome-icon icon="fa-solid fa-gear" size="lg" />
            <router-link class="ml-4" :to="{ name: 'AccountProfile' }">Account Setting</router-link>
        </div>
        <div class="flex items-center mt-4 ml-1" :class="{ 'text-primary': isActive('AccountVoucher') }">
            <font-awesome-icon icon="fa-solid fa-percent" size="lg" />
            <router-link class="ml-4" :to="{ name: 'AccountVoucherIndex' }">Voucher</router-link>
        </div>
        <div class="flex items-center mt-4" :class="{ 'text-primary': isActive('AccountReview') }">
            <font-awesome-icon icon="fa-solid fa-message" size="lg" />
            <router-link class="ml-4" :to="{ name: 'AccountReviewIndex' }">Reviews</router-link>
        </div>
        <div class="flex items-center mt-4" :class="{ 'text-primary': isActive('CrossSelling') }">
            <font-awesome-icon icon="fa-solid fa-file-invoice" size="lg" />
            <router-link class="ml-4" :to="{ name: 'CrossSelling' }">Cross Selling</router-link>
        </div>
        <div class="flex items-center mt-4" :class="{ 'text-primary': isActive('Article') }" v-if="hasAccess">
            <font-awesome-icon icon="fa-solid fa-file-lines" size="lg" />
            <router-link class="ml-4" :to="{ name: 'Article' }">Article</router-link>
        </div>
        <div class="flex items-center mt-4" :class="{ 'text-primary': isActive('WaiverAdmin') }" v-if="hasAccessWaiver">
            <font-awesome-icon icon="fa-solid fa-gear" size="lg" />
            <router-link class="ml-4" :to="{ name: 'WaiverAdmin' }">Waiver Admin</router-link>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AccountMenu',
        computed: {
            hasAccess(){
                return this.$store.state.user.admin == "1";
            },
            hasAccessWaiver(){
                return this.$store.state.user.admin == "1" || this.$store.state.user.status_merchant == "1" || this.$store.state.user.id_user == "4";
            }
        },
        methods: {
            isActive(name) {
                return this.$route.matched[1].name == name;
            }
        }
    };
</script>
